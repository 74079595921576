<template>
  <div class="index container">
    <transition name="fade">
      <router-view @showParent="showParent" />
    </transition>

    <!-- Main Page -->
    <div v-if="showParentPage">
      <v-breadcrumbs>
        <v-breadcrumbs-item :exact="true" :to="{ name: 'home' }">
          Home
        </v-breadcrumbs-item>
        <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
        <v-breadcrumbs-item :exact="true" :to="{ name: 'productSpecs' }" active>
          Product Specs
        </v-breadcrumbs-item>
      </v-breadcrumbs>

      <div class="d-flex flex-column flex-lg-row">
        <v-text-field
          dense
          v-model="searchTerms"
          @keyup.enter="search"
          @click:clear="clearSearch"
          @click:append="search"
          placeholder="Press enter to begin search"
          append-icon="mdi-magnify"
          outlined
          clearable
          label="Search"
        />
        <v-btn
          class="ml-0 ml-lg-4 mb-2 mb-lg-0 align-self-end align-self-lg-start"
          color="primary"
          :to="{ name: 'productSpecsCreate' }"
        >
          Create Product Specs
        </v-btn>
      </div>

      <div class="table-container">
        <v-data-table
          :headers="headers"
          :items="productSpecsList"
          :items-per-page="pagination.pageSize"
          :loading="!productSpecsProcessed"
          :server-items-length="pagination.totalSize"
          :page="pagination.current"
          :disable-sort="true"
          item-key="id"
          class="elevation-1"
          @update:options="pageChanged"
        >
          <!-- Show Product Name -->
          <template v-slot:item.productName="{ item }">
            {{ item.productName }}
          </template>

          <!-- Show company count & "Manage" button -->
          <template v-slot:item.companyCount="{ item }">
            <v-btn
              text
              small
              class="ml-2"
              @click="openCompanyDialog(item)"
            >
              {{ item.companies ? item.companies.length : 0 }}
            </v-btn>
          </template>

          <!-- Existing actions column -->
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="edit(item)">mdi-pencil</v-icon>
            <v-icon small class="mr-2" @click="remove(item)">mdi-delete</v-icon>
          </template>
        </v-data-table>
      </div>
    </div>

    <ManageProductSpecCompanies
      :dialogVisible="showCompaniesDialog"
      :productSpecs="selectedProductSpec"
      @update:dialogVisible="val => showCompaniesDialog = val"
      @reload="refreshSelectedSpec"
    />

    <!-- Confirmation Dialog for deletions -->
    <confirmation-dialog ref="confirm" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

// *** Import your separate child component here ***
import ManageProductSpecCompanies from "@/components/productSpecs/ManageProductSpecCompanies.vue";

export default {
  name: "ProductSpecsIndex",
  components: {
    ManageProductSpecCompanies
  },
  data() {
    return {
      pagination: {
        current: 1,
        pageSize: 10,
        totalSize: 100,
      },
      productSpecsList: [],
      showParentPage: true,
      headers: [],
      sort: {
        key: null,
      },
      searchTerms: "",

      // (B) For the child dialog
      showCompaniesDialog: false,  // toggles the ManageProductSpecCompanies dialog
      selectedProductSpec: null,   // the spec row we want to manage
    };
  },
  computed: {
    ...mapGetters("productSpecs", ["productSpecsProcessed", "getProductSpecs"]),
  },
  methods: {
    ...mapActions("messages", [
      "addErrorMessage",
      "addMessage",
      "addSuccessMessage",
    ]),
    ...mapActions("productSpecs", ["deleteProductSpecs", "fetchProductSpecs"]),

    openCompanyDialog(item) {
      this.selectedProductSpec = item;
      this.showCompaniesDialog = true;   // triggers child to open
    },
    closeCompanyDialog() {
      this.showCompaniesDialog = false;
      this.selectedProductSpec = null;
    },

    // Called by child's @reload
    refreshSelectedSpec() {
      // Easiest approach is to re-fetch the entire table
      this.getResources();
    },

    getResources() {
      const params = {
        page: (this.pagination.pageIndex && this.pagination.pageIndex > 0)
          ? this.pagination.pageIndex - 1
          : 0,
        size: this.pagination.pageSize,
        "product.name": this.searchTerms,
      };
      this.fetchProductSpecs(params)
        .then((response) => {
          if (response) {
            this.pagination.totalSize = response.totalElements;
            this.productSpecsList = response.content || [];
            this.prepHeaders();
          }
        })
        .catch((error) => {
          this.addErrorMessage("Server response with error " + error);
        });
    },

    // existing code for editing, removing, etc.
    edit(item) {
      this.$router.push({ name: "productSpecsEdit", params: { id: item.id } });
    },
    async remove(item) {
      let confirm = await this.$refs.confirm.open(
        "Confirm Delete",
        "Do you want to delete this productSpecs?"
      );
      if (confirm) {
        this.deleteProductSpecs(item.id)
          .then(() => {
            this.addSuccessMessage("productSpecs was deleted successfully");
            this.getResources();
          })
          .catch(() => {
            this.addErrorMessage("Server response with error ");
          });
      }
    },

    search() {
      this.getResources();
    },
    clearSearch() {
      this.searchTerms = "";
      this.getResources();
    },

    // build the table headers dynamically
    prepHeaders() {
      const baseHeaders = [
        {
          text: "Product",
          value: "productName",
          align: "start",
          sortable: true,
        },
        {
          text: "Companies",
          value: "companyCount",
          align: "start",
          sortable: false,
        },
      ];

      const uniqueElements = this.productSpecsList.reduce((set, p) => {
        if (p.elements) {
          p.elements.forEach((element) => {
            const symbol = element.element.symbol;
            set.add(symbol);
            p[symbol] = this.printElementSpec(element);
          });
        }
        return set;
      }, new Set());

      const elementHeaders = [];
      uniqueElements.forEach((symbol) => {
        elementHeaders.push({
          text: symbol,
          value: symbol,
          align: "start",
          sortable: true,
        });
      });

      this.headers = [
        ...baseHeaders,
        ...elementHeaders,
        {
          text: "Actions",
          value: "actions",
          align: "end",
          sortable: false,
        },
      ];
    },
    printElementSpec(element) {
      if (element.minPercent && !element.maxPercent) {
        return `${element.minPercent}+`;
      }
      if (!element.minPercent && element.maxPercent) {
        return `<${element.maxPercent}`;
      }
      if (element.minPercent && element.maxPercent) {
        return `${element.minPercent} - ${element.maxPercent}`;
      }
      return "";
    },
    pageChanged(pagination) {
      this.pagination.pageIndex = pagination.page;
      this.pagination.pageSize = pagination.itemsPerPage;
      this.getResources();
    },
    showParent(show) {
      this.showParentPage = show;
    },
  },
  watch: {
    "pagination.numberRegisterForPage": function () {
      this.pagination.current = -1;
    },
  },
  beforeRouteUpdate(to, from, next) {
    const toDepth = to.path.split("/").length;
    const fromDepth = from.path.split("/").length;
    this.showParentPage = toDepth < fromDepth;
    if (this.showParentPage) {
      this.getResources();
    }
    next();
  },
  created() {
    this.getResources();
  },
};
</script>

<style scoped>
.table-container {
  margin-top: 20px;
}
</style>
