import { render, staticRenderFns } from "./ProductSpecsIndex.vue?vue&type=template&id=0ef6db38&scoped=true"
import script from "./ProductSpecsIndex.vue?vue&type=script&lang=js"
export * from "./ProductSpecsIndex.vue?vue&type=script&lang=js"
import style0 from "./ProductSpecsIndex.vue?vue&type=style&index=0&id=0ef6db38&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ef6db38",
  null
  
)

export default component.exports